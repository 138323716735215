import React from 'react'
import {Deutsch, English, Type, LinkABOUT,LinkWORK, LinkHOME, LinkDE, LinkEN, Page, SiteTitle} from '../components/linksetc.js'
import Layout from '../components/layout'
import { Link } from 'gatsby'




const IndexPage = ({ location }) => (

  <Layout>
        

  <SiteTitle string="Why do I need a copywriter?" />
  <meta name="description" content="Do you really need a copywriter? Here are a few reasons why (not)." />

      <Page location={location}>



          <English Language={location.search}>
                <Type>
                <p/>Do you really need a copywriter?
                <p/>

                <p/>You can communicate your ideas clearly in writing.
                <br/>→ You do not need a copywriter.

                <p/>You can write well but do not feel like doing this.
                <br/>→ I know the feeling. You do not need a copywriter, but can afford them as a luxury.

                <p/>Your writing is solid but lacks flashy buzzwords.
                <br/>→ Leave it as it is. You do not need a copywriter.
<p/>
                <p/>People outside your field do not understand your service.
                <br/>→ You need a copywriter to reduce complexity.

                <p/>Not even you believe your own story.
                <br/>→ You need a copywriter to invent and sharpen your arguments.
<p/>
                <p/>Your content is there but it does not feel right.
                <br/>→ You need a copywriter to find the right verbal identity for your brand.
<p/>
                <p/>Your writing is just a bunch of exchangeable bullshit buzzwords.
                <br/>→ You need a copywriter to find and express the substance of your product.
                <p/>
                <p/>Your arguments and story are there but seem fragmented.
                <br/>→ You need a copywriter to create continuity in logic and style.
<p/>
                <p/>Your writing is good but too damn long, nobody will ever read this.
                <br/>→ You need a copywriter to get to the point.
<p/>
                <p/>You can write but it needs to be done by yesterday.
                <br/>→ Some copywriters (=me) are fast, just throw some money at them.
<p/>
                <p/>You can write well but you have better things to do.
                <br/>→ Just throw some money at the copywriter.
<p/>
                <p/>It's the end of the year and this budget must be burned.
                <br/>→ You need an expensive copywriter (=me).

                </Type>

          </English>


          <Deutsch Language={location.search}>
          <Type>
                <p/>Brauchst du wirklich einen Texter?
                <p/>

                <p/>Du kannst deine Ideen klar schriftlich kommunizieren.
                <br/>→ Du brauchst keinen Texter.

                <p/>Du kannst gut schreiben aber hast keine Lust.
                <br/>→ Kenne ich. Du brauchst (leider) einen Texter.

                <p/>Du schreibst solide aber es fehlen die Buzzwords.
                <br/>→ Lass es einfach so. Du brauchst keinen Texter.
<p/>
                <p/>Andere Leute verstehen euren saukomplizierten Service nicht.
                <br/>→ Du brauchst einen Texter, der die Komplexität reduziert.

                <p/>Nicht mal du glaubst, was du da verzapfst.
                <br/>→ Du brauchst einen Texter, der Argumente erfindet.
<p/>
                <p/>Der Inhalt ist da, fühlt sich aber unpassend an.
                <br/>→ Du brauchst einen Texter, der die passende verbale Identität zu deinem Brand findet.
<p/>
                <p/>Dein Geschreibsel klingt wie eine Ansammlung austauschbarer Modewörter.
                <br/>→ Du brauchst einen Texter, der die Substanz deines Produkts findet und kommuniziert.
                <p/>
                <p/>Deine Argumente oder Geschichten sind irgendwie da aber fragmentiert.
                <br/>→ Du brauchst einen Texter, der mit Logik und Stil Kontinuität schafft.
<p/>
                <p/>Dein Geschreibsel ist gut aber zu lang, das liest keiner.
                <br/>→ Du brauchst einen Texter, um auf den Punkt zu kommen. 
<p/>
                <p/>Du kannst schreiben, aber es sollte gestern fertig sein.
                <br/>→ Einige Texter (=ich) sind schnell, du musst sie nur mit Geld bewerfen.
<p/>
                <p/>Du kannst gut schreiben, hast aber besseres zu tun.
                <br/>→ Überhäufe den Texter mit Geld.
<p/>
                <p/>Es ist Jahresende und unser Budget muss noch aufgebraucht werden.
                <br/>→ Ihr braucht einen teuren Texter (=mich).

                </Type>

          </Deutsch>








      </Page>
      </Layout>

)

export default IndexPage
